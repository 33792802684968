<div style="padding: 10px"><app-header></app-header></div>

<div class="topic">
	<div class="title">
		<h1 class="title__text">REGULAMENTO ESFERA RESTAURANTES</h1>
	</div>
	<div class="box boxOne">
		<p class="text">
			Para usufruir do acúmulo de pontos, ao chegar ao restaurante, mencione que a reserva ou check-in
			foi feito pelo Programa Esfera Restaurantes. O pagamento deverá ser feito pelo aplicativo da
			Esfera, em qualquer cartão de crédito previamente cadastrado. Cabe ressaltar que o check-in,
			diferentemente da reserva, não garante uma mesa para o usuário.
			<br /><br />
			Os pontos podem variar de acordo com cada restaurante e são contabilizados pelo valor pago através
			do aplicativo considerando o consumo, ou seja, não é contabilizado para ganhos dos pontos a taxa
			de serviço.
			<br /><br />
			Os pontos referentes ao pagamento da conta pelo aplicativo, considerando a condição oferecida pelo
			restaurante naquela data – por exemplo, 2 (dois) pontos a cada real – são creditados em até 1 (um)
			dia útil. Em caso de o pagamento ser feito com um cartão Santander, do tipo que acumula pontos,
			também haverá o ganho dos pontos referentes à transação, sendo que referidos pontos são creditados
			em até 10 (dez) dias corridos.
		</p>
	</div>
</div>

<div style="height: 10px"></div>

<div class="topic">
	<div class="title">
		<h1 class="title__text">TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE</h1>
	</div>
	<div class="box boxOne">
		<!--Session 1-->
		<div class="box">
			<p class="text sub">Bem-vindo ao ESFERA RESTAURANTES.</p>
			<div class="box">
				<p class="text">
					A seguir apresentamos a você (USUÁRIO) os Termos e Condições do PROGRAMA ESFERA
					RESTAURANTES oferecido pela Esfera, uma empresa do Conglomerado Santander, em parceria com
					a TAGME.
					<br /><br />
					A TAGME é uma empresa parceira da Esfera, responsável pela gestão de reservas e esperas
					multicanais perante os restaurantes participantes do PROGRAMA ESFERA RESTAURANTES. Assim,
					USUÁRIO, ao realizar uma reserva, os seus dados também passam a ser tratados pela TAGME e,
					portanto, ao PROGRAMA ESFERA RESTAURANTES aplica-se este documento e, também, as
					disposições constantes no seguinte link.
					<br /><br />
					Este documento estabelece as regras a serem cumpridas por todos que acessam ou utilizam as
					funcionalidades do programa. Como condição para acesso e uso dos serviços oferecidos, o
					USUÁRIO deve declarar que fez a leitura completa e atenta das regras deste documento,
					estando plenamente ciente e de acordo com elas.
				</p>
			</div>
		</div>

		<!--Session 2-->
		<div class="box">
			<p class="text sub">1. INFORMAÇÕES GERAIS SOBRE O PROGRAMA</p>
			<div class="box">
				<p class="text">
					1.1. O PROGRAMA permite ao USUÁRIO ter acesso a sugestões de estabelecimentos
					gastronômicos e seus serviços, tais como: menu completo, sistema de reserva e check-in,
					entre outros, nas principais capitais e cidades do Brasil.
					<br /><br />
					1.2. Para acessar o PROGRAMA de forma segura, é de inteira responsabilidade do USUÁRIO
					dispor de recursos compatíveis, serviço de conexão à Internet com antivírus e firewall
					habilitados, softwares devidamente atualizados – tais como navegadores e gerenciadores de
					download e upload –, além da adoção de medidas de segurança mínimas, tais como uso de
					senha segura.
					<br /><br />
					1.3. O PROGRAMA e suas funcionalidades são apresentados aos USUÁRIOS na maneira como estão
					disponíveis, podendo passar por constantes aprimoramentos e atualizações, obrigando-se a
					TAGME a:
				</p>
				<div class="box">
					<p class="text">
						1.3.1. Preservar a funcionalidade do PROGRAMA, evitando links quebrados. Manter a
						utilização de layout adequado à usabilidade e navegabilidade, sempre que possível.
						Envidar os melhores esforços no sentido de manter as informações e materiais contidos
						neste PROGRAMA tão precisos, atualizados e completos quanto possível.
						<br /><br />
						1.3.2. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de
						modo que exista a exata percepção das operações realizadas.
						<br /><br />
						1.3.3. Garantir, por meio do estado da técnica disponível, o sigilo dos dados pessoais
						quando inseridos nas funcionalidades oferecidas no PROGRAMA, sendo realizado o
						tratamento de seus dados pessoais com finalidades específicas e de acordo com as bases
						legais previstas na Lei Geral de Proteção de Dados (Lei nº 13.709/18 ou “LGPD”).
						<br /><br />
						1.3.4. Remover os conteúdos que forem publicados contendo:<br /><br />
						a. Ofensa à honra, imagem, reputação e dignidade de terceiros;<br />
						b. Pornografia, pedofilia, e outras modalidades de satisfação sexual;<br />
						c. Racismo ou discriminação de qualquer natureza;<br />
						d. Bullying, stalking ou qualquer outra espécie de constrangimento ilegal ou
						assédio;<br />
						e. Manifesta violação a direito autoral ou direito de imagem e personalidade;<br />
						f. Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;<br />
						g. Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes,
						estupro, homicídio, estelionato, dentre outros;<br />
						h. Manifesta prática de ato contrário à lei, à ordem pública ou aos bons costumes;<br />
						i. Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao
						bem-estar físico e/ou psicológico de alguém;<br />
						j. Atividade comercial similar a TAGME ou que o utilize para angariar recursos por
						publicidade de terceiros ao invés de praticar a interação regular do ambiente; e<br />
						k. Erros ou suspeita de equívocos.
					</p>
				</div>
				<br />
				<p class="text">
					1.4. A TAGME envida seus esforços para a disponibilidade contínua e permanente do
					PROGRAMA. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária
					decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como
					desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e acesso à
					Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade.
				</p>
				<div class="box">
					<p class="text">
						1.4.1. Se isso ocorrer, a TAGME fará o que estiver ao seu alcance para restabelecer o
						acesso ao PROGRAMA o mais breve possível, dentro das limitações técnicas de seus
						serviços e dos serviços de terceiros, dos quais o PROGRAMA depende para ficar
						disponível.
					</p>
				</div>
				<br />
				<p class="text">
					1.5. Eventuais procedimentos de manutenção serão informados por meio dos canais oficiais
					de comunicação da TAGME (a exemplo, mas não se limitando a e-mails, perfis oficiais em
					mídias sociais ou telefone de atendimento), caso seja necessário que esta fique
					indisponível por longos períodos.
				</p>
			</div>
		</div>

		<!--Session 3-->
		<div class="box">
			<p class="text sub">2. FUNCIONALIDADES DO PROGRAMA</p>
			<div class="box">
				<p class="text">
					2.1. O PROGRAMA permite ao USUÁRIO ter acesso a sugestões de estabelecimentos
					gastronômicos e seus serviços, tais como: menu completo, sistema de reserva e check-in,
					entre outros, nas principais capitais e cidades do Brasil.
					<br /><br />
					2.2. Os serviços do PROGRAMA são destinados a pessoas físicas maiores de 18 (dezoito)
					anos. Caso o USUÁRIO não esteja de acordo com o exigido, deverá solicitar a assistência de
					seus responsáveis legais.
					<br /><br />
					2.3. Para visualizar as dicas disponibilizadas no PROGRAMA, o USUÁRIO deverá ser
					cadastrado no programa de fidelidade da Esfera e acessar a opção ESFERA RESTAURANTES. Para
					usufruir de todos os benefícios oferecidos pelo PROGRAMA, o usuário deverá cadastrar o seu
					cartão de crédito.
				</p>
				<div class="box">
					<p class="text">
						2.3.1. Se a qualquer momento for constatado que o USUÁRIO forneceu informações falsas
						ou não condizentes com a realidade, a TAGME se reserva ao direito de suspender o seu
						uso, sem prejuízo de adotar as medidas que entender cabíveis.
					</p>
				</div>
			</div>
		</div>

		<!--Session 4-->
		<div class="box">
			<p class="text sub">3. DAS RESPONSABILIDADES E OBRIGAÇÕES DO USUÁRIO</p>
			<div class="box">
				<p class="text">
					3.1. O USUÁRIO se obriga a utilizar com retidão e de forma ética o APLICATIVO, obedecendo
					aos propósitos aqui estabelecidos, sempre respeitando as condições que regem a utilização
					do PROGRAMA e sua finalidade.
					<br /><br />
					3.2. O USUÁRIO reconhece e afirma que todos os acessos realizados após sua autenticação
					bem-sucedida são interpretados como tendo sido feitos por ele próprio de forma
					incontestável. Por isso, será responsável por todos os acessos e operações no PROGRAMA
					praticados e após sua identificação bem-sucedida, inclusive aqueles derivados de uso
					indevido ou divulgação desta para terceiros.
					<br /><br />
					3.3. É de responsabilidade do USUÁRIO deixar seus sistemas de AntiSpam, filtros similares
					ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no
					recebimento dos comunicados e materiais da TAGME, não sendo aceitável nenhuma escusa caso
					não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos
					mencionados.
					<br /><br />
					3.4. Ao acessar o PROGRAMA, o USUÁRIO declara que irá respeitar todos os direitos de
					propriedade intelectual de titularidade da TAGME, incluindo, mas não se limitando a
					marcas, software, direitos autorais, patentes, tal qual todos os direitos referentes a
					terceiros que porventura estejam, ou estiveram, de alguma forma disponíveis no PROGRAMA.
					<br /><br />
					3.5. As funcionalidades que compõem o PROGRAMA são oferecidas na forma de prestação de
					serviço, não conferindo ao USUÁRIO nenhum direito sobre o software utilizado pelo TAGME ou
					sobre suas estruturas de informática que sustentam o PROGRAMA.
				</p>
				<div class="box">
					<p class="text">
						3.5.1. O USUÁRIO está ciente de que poderá ter seu acesso ao PROGRAMA cancelado sem
						aviso prévio caso o utilize para realizar qualquer atividade que viole direitos do
						TAGME ou de terceiros.
					</p>
				</div>
				<br />
				<p class="text">
					3.6. Não é permitido o acesso às áreas de programação PROGRAMA, seu banco de dados ou
					qualquer outro conjunto de informações que faça parte da atividade de Webmastering.
					<br /><br />
					3.7. Também não é autorizado ao USUÁRIO realizar ou permitir engenharia reversa, nem
					traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar,
					divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas
					do PROGRAMA e de suas funcionalidades.
					<br /><br />
					3.8. No PROGRAMA é proibida a utilização de softwares spider, ou de mineração de dados, de
					qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo
					automatizado, tanto para realizar operações massificadas ou para quaisquer outras
					finalidades.
					<br /><br />
					3.9. O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na
					suspensão das funcionalidades e do acesso do USUÁRIO, conforme previsto neste documento.
					<br /><br />
					3.10. A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade do
					PROGRAMA em decorrência de alguma reclamação, deverá ser sempre compreendida como
					demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como
					reconhecimento de culpa ou de qualquer infração pela TAGME a direito de terceiro.
					<br /><br />
					3.11. Na incidência de danos à TAGME ou a terceiros, o responsável se compromete a arcar
					com todas as obrigações de indenizar o sujeito lesado.
				</p>
			</div>
		</div>

		<!--Session 5-->
		<div class="box">
			<p class="text sub">4. DA ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE DA TAGME</p>
			<div class="box">
				<p class="text">
					4.1. A TAGME não se responsabiliza por quaisquer usos indevidos dos conteúdos
					disponibilizados no PROGRAMA. É obrigação exclusiva do USUÁRIO verificar a quem pertence a
					titularidade dos conteúdos e solicitar a licença ou autorização necessária. As licenças e
					autorizações obtidas pela TAGME não permitem o uso dos conteúdos por terceiros.
					<br /><br />
					4.2. A TAGME não se responsabiliza por qualquer dano direto ou indireto ocasionado por
					eventos de terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão
					à internet, inclusive por ações de softwares maliciosos como vírus, cavalos de Tróia, e
					outros que possam, de algum modo, danificar recursos ou a conexão do USUÁRIO em
					decorrência do acesso, utilização ou navegação no PROGRAMA bem como a transferência de
					dados, arquivos, imagens, textos, áudios ou vídeos contidos neste.
					<br /><br />
					4.3. O USUÁRIO não possui qualquer direito para exigir a disponibilidade do PROGRAMA
					conforme melhor lhes convêm, tampouco poderá pleitear indenização ou reparação de danos no
					caso de o PROGRAMA permanecer fora do ar, independente da motivação.
					<br /><br />
					4.4. A TAGME não detém qualquer responsabilidade pela navegação do USUÁRIO nos links
					externos publicados no PROGRAMA, sendo dever a leitura de eventuais Termos de Uso e
					Política de Privacidade dos links acessados e agir conforme o determinado.
				</p>
				<div class="box">
					<p class="text">
						4.4.1. A TAGME não verifica, controla, aprova ou garante a adequação ou exatidão das
						informações ou dados disponibilizados em tais links, não sendo, portanto, responsável
						por prejuízos, perdas ou danos ocorridos pela visita a tais sites, cabendo ao
						interessado verificar a confiabilidade das informações e dados ali exibidos antes de
						tomar alguma decisão ou praticar algum ato.
					</p>
				</div>
			</div>
		</div>

		<!--Session 6-->
		<div class="box">
			<p class="text sub">5. PRIVACIDADE DO USUÁRIO</p>
			<div class="box">
				<p class="text">
					5.1. Os dados pessoais são obtidos (i) diretamente do USUÁRIO por meio do uso do serviço
					da Esfera, (ii) ao se cadastrar voluntariamente no PROGRAMA ou (iii) ao entrar em contato
					por meio dos canais disponíveis.
					<br /><br />
					5.2. O PROGRAMA pode realizar a coleta de dados do USUÁRIO para funcionar adequadamente e
					oferecer a melhor experiência possível da comodidade proposta, a saber:
				</p>
				<div class="box">
					<p class="text">
						a. Dados de uso e acesso ao PROGRAMA, quais sejam:<br />

						▪ Conexões existentes com outros USUÁRIOS, conforme a espécie;<br />
						▪ Quantidade de interações com outros USUÁRIOS, conforme a espécie;<br />
						▪ Quantidade de interações com o PROGRAMA, conforme a espécie;<br />
						▪Lugares marcados como visitados ou nos quais o USUÁRIO se encontra durante
						determinada interação com o PROGRAMA ou com demais USUÁRIOS; e,<br />
						▪ Frequência de uso do PROGRAMA e duração de interações realizadas, quando possível de
						serem medidas.
					</p>
				</div>
				<br />
				<p class="text">
					5.3. Os dados coletados estarão armazenados em ambiente seguro, observado o estado da
					técnica disponível, e somente serão ser acessados por pessoas qualificadas e autorizadas
					pela TAGME.
					<br /><br />
					5.4. Considerando que nenhum sistema de segurança é absolutamente seguro, a TAGME se exime
					de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas,
					vírus ou invasões do banco de dados utilizado pelo PROGRAMA, salvo nos casos em que tiver
					agido com dolo ou culpa.
					<br /><br />
					5.5. Os dados do USUÁRIO descritos em 5.1., além dos conteúdos de caráter não privado
					poderão ser utilizados para as seguintes finalidades:
				</p>
				<div class="box">
					<p class="text">
						a. Responder a eventuais dúvidas e solicitações do USUÁRIO;<br />
						b. Cumprimento de ordem legal ou judicial;<br />
						c. Constituir, defender ou exercer regularmente direitos em âmbito judicial ou
						administrativo;<br />
						d. Garantir a segurança do USUÁRIO;<br />
						e. Manter atualizados os dados de cadastro do USUÁRIO e para fins de contato por
						telefone fixo, celular, correio eletrônico, SMS, mala direta, redes sociais ou por
						outros meios de comunicação;<br />
						f. Realizar o enriquecimento da base de dados dos USUÁRIOS com a finalidade de
						customização dos serviços, melhor atendimento, promoção do PROGRAMA e desenvolvimento
						de novos produtos da TAGME;<br />
						g. Promover o PROGRAMA e/ou de futuros softwares desenvolvidos ou comercializados pela
						TAGME aos contatos do USUÁRIO por meio de envio de mensagens instantâneas ou mensagens
						por meio do PROGRAMA;<br />
						h. Promover o serviço e seus parceiros, comerciais ou não, e informar sobre novas
						oportunidades e benefícios para o USUÁRIO;<br />
						i. Gerar análises e estudos, sejam estatísticos ou identificáveis, com base no
						comportamento de uso das funcionalidades do PROGRAMA;<br />
						j. Aperfeiçoar o uso e a experiência interativa durante a navegação do USUÁRIO no
						PROGRAMA; e,<br />
						k. Aprimorar o funcionamento do PROGRAMA bem como dos demais serviços da TAGME que se
						relacionam, direta ou indiretamente, com o PROGRAMA, seu ambiente ou propostas de uso.
					</p>
				</div>
				<br />
				<p class="text">
					5.6. A base de dados formada pela TAGME, por meio da coleta de dados do pelo PROGRAMA não
					será compartilhada, vendida, cedida, transferida, informada ou alugada a terceiros,
					podendo ser compartilhada apenas com o grupo econômico da TAGME ou parceiros de negócios,
					como a Esfera.
				</p>
				<div class="box">
					<p class="text">
						5.6.1 Mediante a utilização do PROGRAMA, a Esfera poderá tratar, coletar, armazenar e
						compartilhar seus dados pessoais e informações cadastrais com as sociedades sob
						controle direto ou indireto do Santander (“Conglomerado Santander”), sempre com a
						estrita observância à Lei, para fazer ofertas de produtos e serviços adequados e
						relevantes aos seus interesses e necessidades de acordo com o seu perfil, para outras
						hipóteses baseadas em finalidades legítimas ou para a prestação de serviços que
						beneficiem os clientes.
					</p>
				</div>
				<br />
				<p class="text">
					5.7. Os dados adquiridos somente poderão ser acessados por profissionais devidamente
					autorizados pela TAGME, respeitando a necessidade a que serão submetidos, a relevância
					para os objetivos do PROGRAMA e os interesses do USUÁRIO, além de preservar sua
					privacidade, nos termos da lei e deste documento.
					<br /><br />
					5.8. Caso o USUÁRIO deixe de utilizar os serviços disponibilizados pelo PROGRAMA, a TAGME
					poderá, para fins de auditoria e preservação de direitos, permanecer com o registro de
					dados do USUÁRIO pelo período máximo de 5 (cinco) anos, a não ser que seja exigido
					mantê-los por período superior por motivo legal, requisição administrativa de autoridade
					ou ordem judicial, possuindo a faculdade de excluí-los definitivamente segundo sua
					conveniência, nos termos da lei.
					<br /><br />
					5.9. O USUÁRIO poderá exigir da TAGME os dados registrados que lhe dizem respeito, da
					mesma forma que poderá solicitar sua alteração ou exclusão definitiva quando encerrar o
					uso do PROGRAMA. Para tanto, deverá entrar em contato através dos meios disponibilizados
					no PROGRAMA para solicitar essas providências caso não seja oferecida a funcionalidade
					correspondente na interface.
				</p>
				<div class="box">
					<p class="text">
						5.9.1. O pedido de exclusão de dados feito pelo USUÁRIO será totalmente atendido após
						vencer o prazo indicado em 5.8, contudo, ainda que armazenados para fins de auditoria
						e judiciais, não mais passarão pelo tratamento discriminado em 5.5, f., g., h., i., j.
						e k.
					</p>
				</div>
				<br />
				<p class="text">
					5.10. A TAGME efetuará registro de todas as atividades efetuadas pelo USUÁRIO no PROGRAMA,
					também por meio de logs, incluindo:
				</p>
				<div class="box">
					<p class="text">
						a. Endereço IP do USUÁRIO;<br />
						b. Ações efetuadas pelo USUÁRIO no PROGRAMA;<br />
						c. Mensagens, avisos e informações acessadas pelo USUÁRIO no uso do PROGRAMA;<br />
						d. Ferramentas e funcionalidades acessadas pelo USUÁRIO;<br />
						e. Data e horário de cada ação no PROGRAMA;<br />
						f. Informações sobre os recursos utilizados pelo USUÁRIO, localização, sistema
						operacional e outros disponíveis;<br />
						g. Session ID, quando disponível; e,<br />
						h. Outras informações necessárias para a sua adequada autenticação, quando
						necessário.<br />
					</p>
				</div>
				<br />
				<p class="text">
					5.11. Os registros mencionados no item 5.1 poderão ser utilizados pela TAGME como meio
					probatório em casos de:
				</p>
				<div class="box">
					<p class="text">
						a. Atos ilícitos ou contrários a este documento;<br />
						b. Identificação de alterações indevidas em seus sistemas e cadastros;<br />
						c. Demais ações que possam colocar em risco a segurança e continuidade da plataforma,
						do PROGRAMA, de seus USUÁRIOS ou dispositivos nos quais o PROGRAMA está instalado.
					</p>
				</div>
				<br />
				<p class="text">
					5.12. Outras tecnologias poderão ser utilizadas para a obtenção de dados de utilização do
					PROGRAMA pelo USUÁRIO, respeitando sempre os termos deste documento e as opções do USUÁRIO
					a respeito de sua coleta e armazenamento.
				</p>
			</div>
		</div>

		<!--Session 7-->
		<div class="box">
			<p class="text sub">6. DISPOSIÇÕES GERAIS</p>
			<div class="box">
				<p class="text">
					6.1. Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e
					aprimoramento. Assim, a TAGME se reserva ao direito de modificá-los a qualquer momento,
					conforme a finalidade do PROGRAMA ou conveniência de sua controladora, tal qual para
					adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica
					equivalente, cabendo ao USUÁRIO verificá-lo sempre que efetuar o acesso ao PROGRAMA.
					<br /><br />
					6.2. A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do
					presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco
					impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
					<br /><br />
					6.3. Caso alguma disposição destes Termos de Uso seja julgada inaplicável ou sem efeito, o
					restante do documento continua a viger, sem a necessidade de medida judicial que declare
					tal assertiva.
					<br /><br />
					6.4. O PROGRAMA tem como base a data e horários oficiais de Brasília.
					<br /><br />
					6.5. Para cumprimento da legislação de Dados vigente, informamos o nome e endereço de
					e-mail do Encarregado de Proteção de Dados da TAGME: João Victor de Faria Cordeiro -
					joao.victor@tagme.com.
				</p>
			</div>
		</div>

		<!--Session 8-->
		<div class="box">
			<p class="text sub">7. LEI APLICÁVEL E JURISDIÇÃO</p>
			<div class="box">
				<p class="text">
					7.1. Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação
					brasileira, no idioma português, sendo eleito o Foro da Capital do Estado de Rio de
					Janeiro para dirimir qualquer litígio ou controvérsia envolvendo o presente documento,
					salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação
					aplicável.
				</p>
			</div>
		</div>

		<!--Session 9-->
		<div class="box">
			<p class="text sub">8. GLOSSÁRIO</p>
			<div class="box">
				<p class="text">
					8.1. Para os fins deste documento, devem se considerar as seguintes definições e
					descrições para seu melhor entendimento:
				</p>
				<div class="box">
					<p class="text">
						▪ Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em
						massa, pelo bloqueio de mensagens ou as movendo para pasta específica.<br />
						▪ PROGRAMA: Designa o PROGRAMA ESFERA RESTAURANTES.<br />
						▪ TAGME: TAGME – ALVES CUNHA TORRES INFORMATICA LTDA., pessoa jurídica de direito
						privado com sede na cidade do Rio de Janeiro, Estado do Rio de Janeiro, à Rua Dalcídio
						Jurandir, nº 255, Barra da Tijuca, inscrita no CNPJ/MF sob o nº 10.842.304/0001-50.<br />
						▪ IP: Abreviatura de Internet Protocolo. É conjunto alfanumérico que identifica os
						dispositivos dos USUÁRIOS na Internet.<br />
						▪ Layout: Conjunto compreendido entre aparência, design e fluxos do PROGRAMA.<br />
						▪ Link: Terminologia para endereço de Internet.<br />
						▪ USUÁRIOS: Pessoas que acessam ou interagem com as atividades oferecidas.<br />
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div style="height: 20px"></div>
