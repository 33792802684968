import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { Venue } from './venue'
import * as VenueActions from './venue.actions'
import { DEFAULT_LIMIT } from './venue.service'

export interface VenueState {
	venues: Venue[] | null
	loadingVenues: boolean
	selectedVenue: Venue | null
	// TODO: add interface for selectedPoints
	selectedPoints: { value: string } | null // Benefit Object {value: string}
	loadingSelectedVeune: boolean
	error: string
	allLoaded: boolean
	loadingMoreVenues: boolean
	location: { latitude: number; longitude: number; maxDistance: number } | null
}

const initialState: VenueState = {
	venues: null,
	selectedPoints: null,
	loadingVenues: false,
	selectedVenue: null,
	loadingSelectedVeune: false,
	error: '',
	location: null,
	loadingMoreVenues: false,
	allLoaded: false,
}

const getVenueFeatureState = createFeatureSelector<VenueState>('venues')

export const getSelectedPoints = createSelector(getVenueFeatureState, state => state.selectedPoints)
export const getVenues = createSelector(getVenueFeatureState, state => state.venues)
export const getVenue = createSelector(getVenueFeatureState, state => state.selectedVenue)
export const getError = createSelector(getVenueFeatureState, state => state.error)
export const isAllLoaded = createSelector(getVenueFeatureState, state => state.allLoaded)
export const isLoadingVenues = createSelector(getVenueFeatureState, state => state.loadingVenues)
export const isLoadingSelectedVenue = createSelector(
	getVenueFeatureState,
	state => state.loadingSelectedVeune
)
export const isLoadingMoreVenues = createSelector(getVenueFeatureState, state => state.loadingMoreVenues)
export const getLocation = createSelector(getVenueFeatureState, state => state.location)

export const venueReducer = createReducer<VenueState>(
	initialState,
	on(VenueActions.loadOneVenueSuccess, (state, action): VenueState => {
		const selectedPoints = (() => {
			const points = action.venue.benefits.find(benefit => benefit.type === 'points')
			const exception = points.exceptions?.find((v: any) => v.venueId === action.venue._id)
			if (exception) {
				return exception
			}
			return points
		})()
		return {
			...state,
			error: '',
			loadingSelectedVeune: false,
			selectedVenue: action.venue,
			selectedPoints,
		}
	}),
	on(VenueActions.loadOneVenueFailure, (state, action): VenueState => {
		return {
			...state,
			loadingSelectedVeune: false,
			error: action.error,
		}
	}),
	on(VenueActions.loadOneVenue, (state): VenueState => {
		return {
			...state,
			loadingSelectedVeune: true,
		}
	}),
	on(VenueActions.getVenues, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
		}
	}),
	on(VenueActions.loadVenuesSuccess, (state, action): VenueState => {
		return {
			...state,
			error: '',
			selectedVenue: null,
			venues: action.venues.filter(Boolean),
			loadingVenues: false,
			allLoaded: action.venues.length < DEFAULT_LIMIT,
		}
	}),
	on(VenueActions.loadVenuesFailure, (state, action): VenueState => {
		return {
			...state,
			error: action.error,
			loadingVenues: false,
			venues: [],
			allLoaded: false,
		}
	}),
	on(VenueActions.loadVenues, (state): VenueState => {
		return {
			...state,
			venues: null,
			loadingVenues: true,
		}
	}),
	on(VenueActions.loadMoreVenuesSuccess, (state, action): VenueState => {
		const venues = action.venues.filter(Boolean)
		return {
			...state,
			error: '',
			venues: state.venues ? [...state.venues, ...venues] : [...venues],
			allLoaded: action.venues.length < DEFAULT_LIMIT,
			loadingMoreVenues: false,
		}
	}),
	on(VenueActions.loadMoreVenuesFailure, (state, action): VenueState => {
		return {
			...state,
			allLoaded: false,
			error: action.error,
			venues: [],
			loadingMoreVenues: false,
		}
	}),
	on(VenueActions.loadMoreVenue, (state): VenueState => {
		return {
			...state,
			loadingMoreVenues: true,
		}
	}),
	on(VenueActions.setLocation, (state, action): VenueState => {
		return {
			...state,
			location: {
				latitude: action.latitude,
				longitude: action.longitude,
				maxDistance: action.maxDistance,
			},
		}
	})
)
