import { createAction, props } from '@ngrx/store'
import { BillingAddress, EncryptCardDataDto } from 'src/app/page/wallet/add-card/add-card.interface'
import { Category, Client } from './client'

// Client
export const getClient = createAction('[Client Redux] Get Client')
export const loginClient = createAction('[Client API] Load Client', props<{ id: string }>())
export const loginClientSSO = createAction(
	'[Client API] Load Client Esfera/SSO',
	props<{ esferaToken: string; esferaIdentifier: string }>()
)
export const loginClientSuccess = createAction(
	'[Client API] Load Client Success',
	props<{ client: Client }>()
)
export const loginClientFailure = createAction('[Client API] Load Client Fail', props<{ error: string }>())

export const setClient = createAction('[Client API] Set Client', props<{ client: Client }>())

export const addNewCard = createAction(
	'[Client API] Add New Card',
	props<{
		encryptData: EncryptCardDataDto
		billingAddress: BillingAddress
		mainCard: boolean
		cpf?: string
	}>()
)

export const setLoadingTrue = createAction('[Client API] Loading ')

export const addNewCardSuccess = createAction(
	'[Client API] Add New Card Success',
	props<{ gatewayId: string }>()
)

export const setSelectedCardByGatewayId = createAction(
	'[Client API] Set Selected Card By GatewayId',
	props<{ gatewayId: string }>()
)

export const addNewCardFailure = createAction('[Client API] Add New Card Failure', props<{ error: string }>())

export const updateClient = createAction('[Client State] Update Client')

export const setRedirectUrl = createAction('[Client State] Set Redirect Url', props<{ url: string | null }>())
export const setSelectedCard = createAction('[Client State] Set SelectedCard', props<{ card: Category }>())

export const removeCard = createAction('[Client State] Remove Card', props<{ lastFourDigits: string }>())

export const updateMainCard = createAction(
	'[Client State] Update Main Card',
	props<{ lastFourDigits: string }>()
)

export const updateMainCardError = createAction(
	'[Client API] Update Main Card Fail',
	props<{ error: string }>()
)

export const updateMainCardSuccess = createAction('[Client API] Update Main Card Success')
