import { BillingAddress } from 'src/app/page/wallet/add-card/add-card.interface'

export enum ORDER_STATUS {
	OPEN = 'OPEN',
	CHK_INIT = 'CHK_INIT',
	CHK_2FA = 'CHK_2FA',
	CHK_AUTH = 'CHK_AUTH',
	CHK_DONE = 'CHK_DONE',
	CHK_CANCEL = 'CHK_CANCEL',
	VALIDATED = 'VALIDATED',
}

export enum ORDER_PAYMENT_STATUS {
	READY = 'READY',
	NOT_DONE = 'NOT_DONE',
	DONE = 'DONE',
	FAILED = 'FAILED',
	CANCELED = 'CANCELED',
}

export type OrderFromHistory = {
	_id: string
	intent: 'reservation' | 'waitlist' | 'checkin'
	partySize: number
	sectionLabel: string
	status: ORDER_STATUS
	benefits: {
		_id: string
		type: string
		value: string
		description: string
		name: string
	}[]
	reservationDay: string //'2022-11-25T03:00:00.000Z'
	reservationTime: string //'19:15'
	removeGratuityValue?: boolean
	venueId: string
	clientId: string
	categoryId: string
	partnerId: string
	client: {
		name: string
		lastName: string
		phone: string
		cpf: string
	}
	createdAt: string //'2022-11-25T14:13:58.248Z'
	payment: {
		orderInitialValue: number
		gratuityValue: number
		orderTotalValue: number
		orderTotalPoints: number
		paidAt: string //'2022-11-25T14:18:55.572Z'
		code: string
	}
	venue: {
		_id: string
		address: {
			city: string
			state: string
			neighborhood: string
			address: string
			number: string
			location: [number, number]
		}
		name: string
		partner: {
			partnerId: string
		}
	}
	paymentStatus: ORDER_PAYMENT_STATUS
	canceledAt: string
	gratuityPercent?: number
	venueHasPaymentConfig: boolean
}
// Change after the interface was defined
type PartnerFromHistory = any

export type PaymentOrder = {
	totalValue: number
	bill: number
	gratuityValue: number
	points: number
	paidAt?: string
	code?: string
}

export type CreatePaymentAction = {
	dynamicCVV: string
}

export type CreatePaymentDto = {
	tableOrder: string
	clientId: string
	venueId: string
	paymentData: string
	gatewayId: string
	billingAddress?: BillingAddress
	value: number // Em centavos, mínimo 1000
	cpf?: string
	dynamicCVV: string
	orderId: string
}
