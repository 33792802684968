import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { filter, Subscription, switchMap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { State } from 'src/app/state/app.state'
import { AddCardHelper } from './add-card.helper'
import { EncryptCardDataDto } from './add-card.interface'
import { AddCardService } from './add-card.service'
import * as ClientActions from '../../../state/client/client.actions'
import { Actions, ofType } from '@ngrx/effects'
import { getError, loadingNewCard } from 'src/app/state/client/client.reducers'
import { Item } from 'src/app/lib/select-box/select-box.component'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'

@Component({
	selector: 'app-add-card',
	templateUrl: './add-card.component.html',
	styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
	public creditCardForm!: FormGroup
	modalCvvId = 'modalCvvId' as const
	modalAddCardSuccessId = 'modalAddCardSuccessId' as const
	modalAddCardFailureId = 'modalAddCardFailureId' as const
	loadingNewCard$ = this.store.select(loadingNewCard)
	error$ = this.store.select(getError)
	states = AddCardHelper.STATES_NAME_ID.map(state => ({ name: state.name, value: state.id })) as Item[]
	selectedState = { name: AddCardHelper.DEFAULT_STATE, value: '' }

	private subs = new Subscription()
	constructor(
		private modalService: ModalService,
		private fb: FormBuilder,
		private addCardService: AddCardService,
		private store: Store<State>,
		private actions: Actions,
		private title: Title
	) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Adicionar cartão`)
		this.creditCardForm = this.createCreditCardForm()
		this.subs.add(this.updateAddressFromCep())
		this.subs.add(this.openModalAddCardIfSuccess)
		this.subs.add(this.openModalAddCardIfFailure)
		// this.patchTest()
	}

	private createCreditCardForm(): FormGroup {
		return this.fb.group({
			number: [null, Validators.required],
			name: ['', Validators.required],
			cpf: ['', Validators.required],
			validity: [null, Validators.required],
			cvv: ['', Validators.required],
			billingAddress: this.fb.group({
				zipCode: ['', Validators.required],
				state: ['', Validators.required],
				city: ['', Validators.required],
				address: ['', Validators.required],
			}),
			mainCard: [false],
		})
	}

	private removeSpecialCaractere(str: string) {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	}

	handleSubmit() {
		const { billingAddress, mainCard } = this.creditCardForm.value
		this.store.dispatch(
			ClientActions.addNewCard({
				encryptData: this.encryptData,
				billingAddress,
				mainCard,
				cpf: this.creditCardForm.value.cpf,
			})
		)
	}

	private get encryptData(): EncryptCardDataDto {
		const [expMonth, expYear] = this.creditCardForm.value.validity.split('/')
		const encryptCard = {
			number: this.creditCardForm.value.number,
			holder: this.removeSpecialCaractere(this.creditCardForm.value.name),
			cvv: this.creditCardForm.value.cvv,
			expMonth: +expMonth,
			expYear: +`20${expYear}`,
		}
		return encryptCard
	}

	setState(uf: Item) {
		this.selectedState = { name: uf.name, value: uf.value }
		this.creditCardForm.patchValue({
			billingAddress: {
				state: uf.value,
			},
		})
	}

	private updateAddressFromCep() {
		return this.creditCardForm
			.get('billingAddress.zipCode')
			?.valueChanges.pipe(
				filter(zipCode => zipCode.toString().length >= 8),
				switchMap(zipCode => this.addCardService.getCEP(zipCode))
			)
			.subscribe(address => {
				if (address?.erro) return

				this.selectedState = { name: AddCardHelper.findStateByUf(address.uf), value: address.uf }
				this.creditCardForm.patchValue({
					billingAddress: {
						state: address.uf,
						city: address.localidade,
						address: address.logradouro,
					},
				})
			})
	}

	openModal(id: 'modalCvvId' | 'modalAddCard') {
		this.modalService.open(id)
	}

	modalClose(id: 'modalCvvId' | 'modalAddCard') {
		this.modalService.close(id)
	}

	private get openModalAddCardIfSuccess() {
		return this.actions
			.pipe(ofType(ClientActions.addNewCardSuccess))
			.subscribe(() => this.modalService.open(this.modalAddCardSuccessId))
	}

	private get openModalAddCardIfFailure() {
		return this.actions
			.pipe(ofType(ClientActions.addNewCardFailure))
			.subscribe(() => this.modalService.open(this.modalAddCardFailureId))
	}

	// private patchTest() {
	// 	this.selectedState = { name: 'Rio de Janeiro', value: 'RJ' }
	// 	this.creditCardForm.patchValue({
	// 		name: 'Teste teste',
	// 		number: '5454109734562043',
	// 		validity: '08/24',
	// 		cvv: 223,
	// 		billingAddress: {
	// 			zipCode: '88888888',
	// 			state: 'RJ',
	// 			city: 'RJ',
	// 			address: 'RJ',
	// 		},
	// 	})
	// }
}
