import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ClientActions from './client.actions'
import { Category, Client } from './client'

export interface ClientState {
	client: Client | null
	cards: Category[] | null
	selectedCard: Category | null
	categoryId: string
	error: string | null
	redirectUrl: string | null
	isLoggedIn: boolean
	loadingClient: boolean
	loadingNewCard: boolean
	loadingMainCard: boolean
}

const initialState: ClientState = {
	client: null,
	cards: null,
	error: null,
	selectedCard: null,
	categoryId: '63640d52a3d6d0a755152373',
	redirectUrl: null,
	isLoggedIn: false,
	loadingClient: false,
	loadingNewCard: false,
	loadingMainCard: false,
}

const getClientFeatureState = createFeatureSelector<ClientState>('client')

export const getClient = createSelector(getClientFeatureState, state => ({
	client: state.client,
	selectedCard: state.selectedCard,
	redirectUrl: state.redirectUrl,
}))
export const getCards = createSelector(getClientFeatureState, state => state.cards)
export const getSelectedCard = createSelector(getClientFeatureState, state => state.selectedCard)
export const getError = createSelector(getClientFeatureState, state => state.error)
export const isLoggedIn = createSelector(getClientFeatureState, state => state.isLoggedIn)
export const getClientId = createSelector(getClientFeatureState, state => state.client?._id)
export const loadingClient = createSelector(getClientFeatureState, state => state.loadingClient)
export const loadingNewCard = createSelector(getClientFeatureState, state => state.loadingNewCard)
export const loadingMainCard = createSelector(getClientFeatureState, state => state.loadingMainCard)
export const getCategoryId = createSelector(getClientFeatureState, state => state.categoryId)

export const clientReducer = createReducer<ClientState>(
	initialState,
	on(ClientActions.loginClientSuccess, ClientActions.setClient, (state, action): ClientState => {
		const cards: any[] = action.client.categories.filter((card: any) => card.tokenCard)
		const mainCard = cards.find(card => card.mainCard === true)
		return {
			...state,
			client: action.client,
			cards: cards,
			selectedCard: mainCard || cards[0] || null,
			isLoggedIn: true,
			error: null,
			loadingClient: false,
		}
	}),
	on(ClientActions.loginClientFailure, (state, action): ClientState => {
		return {
			...state,
			client: null,
			error: action.error,
			loadingClient: false,
		}
	}),
	on(ClientActions.loginClient, ClientActions.loginClientSSO, (state): ClientState => {
		return {
			...state,
			loadingClient: true,
		}
	}),
	on(ClientActions.addNewCard, (state): ClientState => {
		return {
			...state,
			loadingNewCard: true,
		}
	}),
	on(ClientActions.addNewCardFailure, (state, action): ClientState => {
		return {
			...state,
			error: action.error,
			loadingNewCard: false,
		}
	}),
	on(ClientActions.addNewCardSuccess, (state): ClientState => {
		return {
			...state,
			loadingNewCard: false,
		}
	}),
	on(ClientActions.setRedirectUrl, (state, action): ClientState => {
		return {
			...state,
			redirectUrl: action.url,
		}
	}),
	on(ClientActions.setSelectedCard, (state, action): ClientState => {
		return {
			...state,
			selectedCard: action.card,
		}
	}),
	on(ClientActions.updateMainCard, (state): ClientState => {
		return {
			...state,
			loadingMainCard: true,
		}
	}),
	on(ClientActions.updateMainCardError, (state, action): ClientState => {
		return {
			...state,
			error: action.error,
			loadingMainCard: false,
		}
	}),
	on(ClientActions.updateMainCardSuccess, (state): ClientState => {
		return {
			...state,
			error: null,
			loadingMainCard: false,
		}
	}),
	on(ClientActions.setSelectedCardByGatewayId, (state, action): ClientState => {
		if (action.gatewayId) {
			return {
				...state,
				selectedCard: state.cards?.find(card => card?.gatewayId === action.gatewayId),
			}
		}
		return {
			...state,
		}
	})
)
