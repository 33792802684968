import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { filter, map, Observable, Subscription, take, tap } from 'rxjs'

// Venue State NgRx
import { State } from '../../state/app.state'
import * as VenueActions from '../../state/venue/venue.actions'
import { getError, getVenues, isLoadingMoreVenues, isLoadingVenues } from '../../state/venue/venue.reducers'

// Address NgRx
import * as AddressActions from '../../state/address/address.action'
import { DEFAULT_ADDRESS_TEXT, getAddresses, getSelectedAddress } from '../../state/address/address.reducers'
import { ModalService } from '../../component/modal/modal.service'
import { VenueService } from '../../state/venue/venue.service'

// Cuisines
import * as CuisinesActions from '../../state/cuisines/cuisines.action'
import {
	DEFAULT_CUISINE_TEXT,
	getCuisines,
	getSelectedCuisine,
} from 'src/app/state/cuisines/cuisines.reducers'
import { Cuisine } from 'src/app/state/cuisines/cuisines'
import { Item } from 'src/app/lib/select-box/select-box.component'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { getOrdersCanPay, getHasPaymentReady } from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../state/order/order.actions'
import { GeolocationService } from 'src/app/shared/services/geo-location.service'
import { campaignService } from 'src/app/shared/services/campaign.service'

@Component({
	templateUrl: './venues.component.html',
	styleUrls: ['./venues.component.scss'],
})
export class VenuesPageComponent implements OnInit, OnDestroy {
	ordersReady$ = this.store.select(getOrdersCanPay)

	pageTitle = 'Restaurantes'
	isOpenFilter = false

	//Payment Ready
	hasPaymentReady$ = this.store.select(getHasPaymentReady)

	// Loading
	isLoadingVenues$ = this.store.select(isLoadingVenues)
	isLoadingMoreVenues$ = this.store.select(isLoadingMoreVenues)

	// Venues
	venues$ = this.store.select(getVenues)
	errorMessage$ = this.store.select(getError)

	// Address
	addresses$ = this.store.select(getAddresses).pipe(
		map(address => [DEFAULT_ADDRESS_TEXT, ...address]),
		map(address => address.map(ad => ({ name: ad, value: ad })))
	)
	selectedAddress$ = this.store
		.select(getSelectedAddress)
		.pipe(map(address => ({ name: address, value: address })))

	// Cuisines
	cuisines$ = this.store.select(getCuisines).pipe(
		filter(cuisine => Boolean(cuisine)),
		map(cuisines => [{ name: DEFAULT_CUISINE_TEXT, _id: null }, ...(cuisines as Cuisine[])] as Cuisine[]),
		map(cuisines => cuisines.map(cui => ({ name: cui.name, value: cui._id })))
	)
	selectedCuisine$ = this.store
		.select(getSelectedCuisine)
		.pipe(map(cuisine => ({ name: cuisine.name, value: cuisine._id })))

	// Modal Id
	modalSearchId = 'modal-search'

	loadMoreVenue$ = this.venueService.loadMoreVenue$.pipe(
		filter((loadMore: boolean) => loadMore),
		tap(() => this.store.dispatch(VenueActions.loadMoreVenue()))
	)

	sub = new Subscription()

	constructor(
		private store: Store<State>,
		public modalService: ModalService,
		private venueService: VenueService,
		private title: Title,
		private geoLocation: GeolocationService,
		public campaignService: campaignService
	) {}

	ngOnInit(): void {
		// Set title
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)

		// Address
		this.store.dispatch(AddressActions.getAddresses())

		// Load More Venue
		this.sub.add(this.loadMoreVenue$.subscribe())

		// Cuisines
		this.store.dispatch(CuisinesActions.getCuisines())

		//Orders
		this.sub.add(
			this.hasPaymentReady$.subscribe({
				next: value => {
					if (value) {
						this.store.dispatch(OrderActions.loadOrderHistory())
					}
				},
			})
		)

		// Get currentLocation
		this.geoLocation.getCurrentLocation()
		this.sub.add(
			this.geoLocation.location$.subscribe({
				next: ({ state, position }) => {
					if (state === 'denied' || state === 'prompt') {
						this.store.dispatch(VenueActions.getVenues())
						return
					}
					if (state === 'granted' && position) {
						this.store.dispatch(
							VenueActions.setLocation({
								latitude: position.latitude,
								longitude: position.longitude,
								maxDistance: 42_500_000,
							})
						)
					}
				},
			})
		)
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

	cleanFilter() {
		this.store.dispatch(VenueActions.cleanFilter())
	}

	addressSelected(address: Item) {
		this.store.dispatch(AddressActions.setCurrentAddress({ address: address.name }))
	}

	cuisineSelected(cuisine: Item) {
		this.store.dispatch(
			CuisinesActions.setCurrentCuisine({ cuisine: { _id: cuisine.value, name: cuisine.name } })
		)
	}

	submitSearch(name: string) {
		this.store.dispatch(VenueActions.loadVenues({ filterVenueDto: { name } }))
		this.modalService.close(this.modalSearchId)
	}

	openModalSearch() {
		this.modalService.open(this.modalSearchId)
	}

	toggleOpenFilter() {
		// take(1) for no need to unsubscribe
		this.selectedCuisine$.pipe(take(1)).subscribe(selectedCuisine => {
			if (selectedCuisine.name === DEFAULT_CUISINE_TEXT) {
				this.isOpenFilter = !this.isOpenFilter
			}
		})
	}
}
