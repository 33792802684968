import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { ofType } from '@ngrx/effects'
import { ActionsSubject, Store } from '@ngrx/store'
import { filter, Subscription, tap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { Item } from 'src/app/lib/select-box/select-box.component'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { CheckInActions } from 'src/app/state/checkin/check-in.actions'
import {
	getAvailabilities,
	getErrorAvailability,
	getErrorConfirm,
	getStatusAvailability,
	getStatusConfirm,
	Status,
} from 'src/app/state/checkin/check-in.reducers'
import { getClient } from 'src/app/state/client/client.reducers'
import { getSelectedPoints, getVenue } from 'src/app/state/venue/venue.reducers'
import * as VenueActions from '../../../state/venue/venue.actions'

@Component({
	templateUrl: './check-in-availability.component.html',
	styleUrls: ['./check-in-availability.component.scss'],
})
export class CheckinAvailabilityPage implements OnInit, OnDestroy, AfterViewInit {
	// Venue
	venue$ = this.store.select(getVenue).pipe(
		filter(value => Boolean(value)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Check-In`))
	)
	selectedPoints$ = this.store.select(getSelectedPoints)
	client$ = this.store.select(getClient)
	errorConfirm$ = this.store.select(getErrorConfirm)
	errorAvailability$ = this.store.select(getErrorAvailability)
	subs = new Subscription()
	statusAvailability: Status | 'unavailable' = 'idle'
	statusConfirm: Status = 'idle'
	availabilities$ = this.store.select(getAvailabilities)

	today = new Date()
	todayFormatted = this.today.toLocaleString('pt-BR', { weekday: 'long', day: 'numeric', month: 'long' })

	form!: FormGroup
	partySizes: Item[] | null = [
		{ name: 'Selecione a qtd de pessoas', value: 'null' },
		{ name: 'Mesa para 1 pessoa', value: '1' },
		{ name: 'Mesa para 2 pessoas', value: '2' },
		{ name: 'Mesa para 3 pessoas', value: '3' },
		{ name: 'Mesa para 4 pessoas', value: '4' },
		{ name: 'Mesa para 5 pessoas', value: '5' },
		{ name: 'Mesa para 6 pessoas', value: '6' },
		{ name: 'Mesa para 7 pessoas', value: '7' },
		{ name: 'Mesa para 8 pessoas', value: '8' },
		{ name: 'Mesa para 9 pessoas', value: '9' },
		{ name: 'Mesa para 10 pessoas', value: '10' },
	]

	modalsId = {
		modalRegisterCard: 'modalRegisterCardId',
		modalErrorConfirmCheckin: 'modalErrorConfirmCheckin',
		modalTerms: 'modalTerms',
	}

	venueId!: string

	constructor(
		private readonly store: Store<State>,
		private readonly fb: FormBuilder,
		private readonly title: Title,
		private readonly route: ActivatedRoute,
		private readonly modalService: ModalService,
		private readonly actions$: ActionsSubject,
		private readonly router: Router
	) {}

	ngOnInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('id') as string
		this.store.dispatch(VenueActions.loadOneVenue({ venueId: this.venueId }))

		this.form = this.fb.group({
			confirm: [null, [Validators.required]],
			partySize: [null, [Validators.required]],
		})

		this.subs.add(
			this.client$.subscribe({
				next: client => {
					if (client.selectedCard) {
						this.store.dispatch(CheckInActions.loadAvailabilities({ venueId: this.venueId }))
					}
				},
			})
		)

		// Subscribe to status availability
		this.subs.add(
			this.store.select(getStatusAvailability).subscribe({
				next: status => {
					this.statusAvailability = status
				},
			})
		)

		// Subscribe to status confirm
		this.subs.add(
			this.store.select(getStatusConfirm).subscribe({
				next: status => {
					this.statusConfirm = status
				},
			})
		)

		// subscribe to
		this.subs.add(
			this.actions$
				.pipe(ofType(CheckInActions.checkinConfirmSuccess))
				.subscribe(() => this.router.navigate(['../checkin-sucesso'], { relativeTo: this.route }))
		)
	}

	ngAfterViewInit(): void {
		this.subs.add(
			this.client$.subscribe({
				next: client => {
					if (!client.selectedCard) {
						this.modalService.open(this.modalsId.modalRegisterCard)
					}
				},
			})
		)

		this.subs.add(
			this.errorConfirm$.pipe(filter(value => Boolean(value))).subscribe(() => {
				this.modalService.open(this.modalsId.modalErrorConfirmCheckin)
			})
		)
	}

	setPartySize(partySizeItem: Item) {
		this.form.get('partySize')?.patchValue(+partySizeItem.value)
	}

	get isDisableForm() {
		return !this.form.get('confirm')?.value || this.form.invalid || this.statusConfirm === 'pending'
	}

	get checkInDate() {
		const tzoffset = this.today.getTimezoneOffset() * 60000 //offset in milliseconds
		const [day, time] = new Date(Date.now() - tzoffset).toISOString().slice(0, -8).split('T')
		return { day, time }
	}

	confirmCheckin() {
		if (this.form.invalid) {
			return
		}
		const partySize = this.form.get('partySize')?.value
		this.store.dispatch(
			CheckInActions.checkinConfirm({
				day: this.checkInDate.day,
				time: this.checkInDate.time,
				partySize: partySize,
			})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	goToReservation() {
		this.router.navigate(['../reserva'], { relativeTo: this.route })
	}

	openTerms() {
		this.modalService.open(this.modalsId.modalTerms)
	}
}
