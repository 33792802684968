import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SwiperOptions } from 'swiper'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, merge, take, tap } from 'rxjs'

import { ModalService } from '../../../component/modal/modal.service'
import { State } from '../../../state/app.state'
import * as VenueActions from '../../../state/venue/venue.actions'
import { getSelectedPoints, getVenue, isLoadingSelectedVenue } from '../../../state/venue/venue.reducers'
import { getSlides } from './venue-detail.helper'
import { environment } from '../../../../environments/environment'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { Title } from '@angular/platform-browser'

@Component({
	templateUrl: './venue-detail.component.html',
	styleUrls: ['./venue-detail.component.scss'],
})
export class VenueDetailComponent implements OnInit {
	config: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 50,
		navigation: true,
		pagination: { clickable: true },
		scrollbar: { draggable: true },
	}

	// Modals Id
	modalAboutId = 'modal-about' as const
	modalScheduleId = 'modal-schedules' as const

	// Venue and related venue
	venue$ = this.store.select(getVenue).pipe(
		filter(venue => Boolean(venue)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Venue Detail`))
	)
	slides$ = this.venue$.pipe(map(venue => getSlides(venue?.partner.images.thumb)))
	phones$ = this.venue$.pipe(map(venue => venue?.phone.map(phone => phone?.value ?? '')))

	selectedPoints$ = this.store.select(getSelectedPoints)

	// LiveMenuURL
	alternateMenu$ = this.venue$.pipe(
		filter(venue => Boolean(venue?.partner.alternateMenu)),
		map(venue => `${environment.liveMenu.url}/${venue?.partner.alternateMenu}`)
	)
	menu$ = this.venue$.pipe(
		filter(venue => Boolean(!venue?.partner.alternateMenu)),
		map(venue => `${environment.liveMenu.url}/${venue?._id}`)
	)
	liveMenuURL$ = merge(this.alternateMenu$, this.menu$).pipe(map(url => `${url}?origin=channels`))

	// Google Maps
	googleMaps$ = this.venue$.pipe(
		filter(venue => Boolean(venue?.address.location)),
		map(venue => venue?.address.location),
		map(location => `${location![1] || 0}, ${location![0] || 0}`),
		map(
			latLong =>
				`https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=568x398`
		)
	)

	isLoadingSelectedVenue$ = this.store.select(isLoadingSelectedVenue)

	// View Model, use async on HTML template → automatically subscribe and unsubscribe
	vm$ = combineLatest([this.venue$, this.slides$, this.phones$, this.liveMenuURL$, this.googleMaps$]).pipe(
		map(([venue, slides, phones, liveMenuURL, googleMaps]) => ({
			venue,
			slides,
			phones,
			liveMenuURL,
			googleMaps,
		}))
	)

	constructor(
		public modalService: ModalService,
		private route: ActivatedRoute,
		private store: Store<State>,
		private title: Title
	) {}

	ngOnInit(): void {
		const id = this.route.snapshot.paramMap.get('id')
		id && this.store.dispatch(VenueActions.loadOneVenue({ venueId: id }))
	}

	openModal(id: 'modal-about' | 'modal-schedules') {
		this.modalService.open(id)
	}

	closeModal(id: 'modal-about' | 'modal-schedules') {
		this.modalService.close(id)
	}
}
