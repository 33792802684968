import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, Observable, startWith, Subscription, switchMap, take, tap } from 'rxjs'
import { ModalService } from 'src/app/component/modal/modal.service'
import { PaymentHelper } from 'src/app/shared/helpers/payment.helper'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getClient } from 'src/app/state/client/client.reducers'
import { PaymentOrder } from 'src/app/state/order/order'
import { getSelectedOrder, getSelectedPoints } from 'src/app/state/order/order.reducers'
import * as OrderActions from '../../../state/order/order.actions'
import { Router } from '@angular/router'
import { campaignService } from 'src/app/shared/services/campaign.service'

const defaultTip = 10

@Component({
	templateUrl: './payment-bill.component.html',
	styleUrls: ['./payment-bill.component.scss'],
})
export class PaymentBillComponent implements OnInit, OnDestroy, AfterViewInit {
	client$ = this.store.select(getClient)
	selectedOrder$ = this.store.select(getSelectedOrder).pipe(
		tap(selectedOrder => {
			if (!selectedOrder) this.router.navigate(['/minhas-reservas'])
		}),
		filter(value => Boolean(value)),
		tap(order => {
			this.title.setTitle(`${PREFIX_TITLE} - Pagamento Conta - ${order?.venue.name}`)
		})
	)
	selectedPoints$ = this.store.select(getSelectedPoints)
	form!: FormGroup

	constructor(
		private modalService: ModalService,
		private store: Store<State>,
		private fb: FormBuilder,
		private title: Title,
		private router: Router,
		public campaignService: campaignService
	) {}

	subs = new Subscription()
	billTip$!: Observable<number>
	suggestedTip = defaultTip
	points$!: Observable<number>
	bill$!: Observable<number>
	totalValue$!: Observable<number>
	removeGratuityValue = false

	modalsId = {
		modalTip: 'modalTipId',
		modalRegisterCard: 'modalRegisterCardId',
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			totalValue: [null, Validators.required],
			tip: [null, Validators.required],
		})
		this.bill$ = this.form.valueChanges.pipe(
			tap(() => console.log(this.suggestedTip)),
			map(form => PaymentHelper.round2decimals(form.totalValue / (1 + this.suggestedTip / 100)))
		)
		this.billTip$ = combineLatest([this.bill$, this.form.valueChanges]).pipe(
			map(([bill, form]) => {
				return (bill * form.tip) / 100
			})
		)
		this.points$ = combineLatest([this.selectedPoints$, this.bill$]).pipe(
			map(([selectedPoints, bill]) =>
				selectedPoints ? Math.floor(bill) * +selectedPoints.value : Math.floor(bill)
			)
		)
		this.totalValue$ = combineLatest([this.bill$, this.billTip$]).pipe(
			map(([bill, billTip]) => bill + billTip)
		)

		this.subs.add(this.updateSuggestTipForm())
		this.subs.add(this.updatePaymentOrder())
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	ngAfterViewInit(): void {
		this.subs.add(
			this.client$.subscribe({
				next: client => {
					if (!client.selectedCard) {
						this.modalService.open(this.modalsId.modalRegisterCard)
					}
				},
			})
		)
	}

	private updateSuggestTipForm() {
		return this.selectedOrder$.subscribe(order => {
			this.suggestedTip = order?.removeGratuityValue ? 0 : order?.gratuityPercent ?? defaultTip
			this.removeGratuityValue = order?.removeGratuityValue ?? false
			this.form.patchValue({ tip: order?.removeGratuityValue ? 0 : this.suggestedTip })
		})
	}

	private updatePaymentOrder() {
		return combineLatest([this.billTip$, this.points$, this.bill$, this.totalValue$]).subscribe(
			([billTip, points, bill, totalValue]) => {
				const paymentOrder: PaymentOrder = {
					totalValue: totalValue,
					bill: bill,
					gratuityValue: billTip,
					points: points,
				}
				this.store.dispatch(OrderActions.setPaymentOrder({ paymentOrder }))
			}
		)
	}

	changeTip(tip: number) {
		this.form.patchValue({
			tip: tip,
		})
	}

	openModaTip() {
		this.modalService.open(this.modalsId.modalTip)
	}
}
