import { createAction, props } from '@ngrx/store'
import { Venue } from './venue'
import { FilterVenuesDto, FindOneVenueDto } from './venue.service'

// Filter
export const cleanFilter = createAction('[Venues Page] Clean Filter')

// Venues
export const getVenues = createAction('[Venues Page] Get Venues')

export const loadVenues = createAction(
	'[Venues Page] Load Venues',
	props<{ filterVenueDto?: FilterVenuesDto }>()
)
export const loadVenuesSuccess = createAction(
	'[Channels API] Load Venues Success',
	props<{ venues: Venue[] }>()
)
export const loadVenuesFailure = createAction('[Channels API] Load Venues Fail', props<{ error: string }>())

// Venue
export const loadOneVenue = createAction(
	'[Venue Page] Load One Venue',
	props<{ venueId: string; findOneVenueDto?: FindOneVenueDto }>()
)

export const loadOneVenueSuccess = createAction(
	'[Channels API] Load One Venue Success',
	props<{ venue: Venue }>()
)

export const loadOneVenueFailure = createAction(
	'[Channels API] Load One Venue Fail',
	props<{ error: string }>()
)

// Infinte Scroll
export const loadMoreVenue = createAction('[Venue Page] Load More Venue')
export const loadMoreVenuesSuccess = createAction(
	'[Channels API] Load More Venue Success',
	props<{ venues: Venue[] }>()
)
export const loadMoreVenuesFailure = createAction(
	'[Channels API] Load More Venue Fail',
	props<{ error: string }>()
)

export const setLocation = createAction(
	'[Channels API] set current location',
	props<{ latitude: number; longitude: number; maxDistance: number }>()
)

export const filterVenuesByLocation = createAction('Channels API] filter venues by location')
