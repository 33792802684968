import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { VenueService } from './venue.service'

import { mergeMap, map, catchError, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { DEFAULT_ADDRESS_TEXT, getSelectedAddress } from '../address/address.reducers'
import { Store } from '@ngrx/store'
import { State } from '../app.state'
import { getLocation, getVenues } from './venue.reducers'
import { DEFAULT_CUISINE_TEXT, getSelectedCuisine } from '../cuisines/cuisines.reducers'

// Actions
import * as VenueActions from './venue.actions'
import * as CuisinesActions from '../cuisines/cuisines.action'
import * as AddressActions from '../address/address.action'
import { getCategoryId } from '../client/client.reducers'
import { GeolocationService } from 'src/app/shared/services/geo-location.service'
@Injectable()
export class VenueEffects {
	constructor(
		private actions$: Actions,
		private venueService: VenueService,
		private store: Store<State>,
		private geolocation: GeolocationService
	) {}

	// Cached
	getVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.getVenues),
			concatLatestFrom(() => this.store.select(getVenues)),
			// Only load venues if the array has at least one venue
			filter(([_, venues]) => !Boolean(venues?.length)),
			map(() => VenueActions.loadVenues({}))
		)
	})

	// Call API (not cached)
	loadVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadVenues),
			concatLatestFrom(() => this.store.select(getCategoryId)),
			mergeMap(([action, categoryId]) =>
				this.venueService.getVenues({ ...action.filterVenueDto, categoryId }).pipe(
					map(venues => VenueActions.loadVenuesSuccess({ venues })),
					catchError(error => of(VenueActions.loadVenuesFailure({ error })))
				)
			)
		)
	})

	// This actions dispatch another action to LoadVenues
	loadVenuesByFilters$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				CuisinesActions.setCurrentCuisine,
				AddressActions.setCurrentAddress,
				VenueActions.cleanFilter
			),
			concatLatestFrom(() => [
				this.store.select(getSelectedCuisine),
				this.store.select(getSelectedAddress),
			]),
			map(([_, cuisine, city]) =>
				VenueActions.loadVenues({
					filterVenueDto: {
						cousine: cuisine.name === DEFAULT_CUISINE_TEXT ? undefined : cuisine._id,
						address: {
							...(city !== DEFAULT_ADDRESS_TEXT && { city }),
						},
						venuesAlreadyInView: [],
					},
				})
			)
		)
	})

	loadVenuesByLocation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.setLocation),
			concatLatestFrom(() => [this.store.select(getVenues)]),
			filter(([_, venues]) => !Boolean(venues?.length) || this.geolocation.firstTime),
			map(([{ latitude, longitude, maxDistance }]) => {
				this.geolocation.firstTime = false
				return VenueActions.loadVenues({
					filterVenueDto: {
						address: {
							location: { latitude, longitude, maxDistance },
						},
						venuesAlreadyInView: [],
					},
				})
			})
		)
	})

	loadOneVenue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadOneVenue),
			concatLatestFrom(() => this.store.select(getCategoryId)),
			mergeMap(([action, categoryId]) =>
				this.venueService.getVenueId(action.venueId, { ...action.findOneVenueDto, categoryId }).pipe(
					map(venue => VenueActions.loadOneVenueSuccess({ venue })),
					catchError(error => of(VenueActions.loadOneVenueFailure({ error })))
				)
			)
		)
	})

	loadMoreVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadMoreVenue),
			concatLatestFrom(() => {
				return [
					this.store.select(getVenues),
					this.store.select(getSelectedAddress),
					this.store.select(getLocation),
				]
			}),
			mergeMap(([_, venues, city, location]) =>
				this.venueService.getVenues({
					address: {
						...(city !== DEFAULT_ADDRESS_TEXT && { city }),
						...(location && { location }),
					},
					venuesAlreadyInView: venues?.map(venue => venue._id),
				})
			),
			map(venues => VenueActions.loadMoreVenuesSuccess({ venues })),
			catchError(error => of(VenueActions.loadMoreVenuesFailure({ error })))
		)
	})
}
