import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, Subscription, take, tap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getSelectedOrder, getSelectedPoints, loadingCancel } from 'src/app/state/order/order.reducers'
import { ModalService } from '../../modal/modal.service'
import * as OrderActions from '../../../state/order/order.actions'
import { Actions, ofType } from '@ngrx/effects'

@Component({
	selector: 'app-order-venue',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
})
export class OrderVenueComponent implements OnInit, OnDestroy, AfterViewInit {
	orderId: string | undefined = undefined
	selectedOrder$ = this.store.select(getSelectedOrder).pipe(filter(value => Boolean(value)))

	selectedPoints$ = this.store.select(getSelectedPoints)
	modalCancelId = 'modalCancelIdDetails'
	loadingCancel$ = this.store.select(loadingCancel)

	subs = new Subscription()

	constructor(
		public modalService: ModalService,
		private store: Store<State>,
		private title: Title,
		private router: Router,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		this.subs.add(
			this.selectedOrder$.subscribe((order: any) => {
				this.orderId = order._id
				this.title.setTitle(`${PREFIX_TITLE} - Minhas Reservas - ${order?.venue.name} - Detalhes`)
			})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	ngAfterViewInit(): void {
		this.subs.add(this.cancelSuccessAction)
		this.subs.add(this.cancelFailureAction)
	}

	cancelOrder() {
		this.selectedOrder$.pipe(take(1)).subscribe((order: any) => {
			this.store.dispatch(OrderActions.cancelOrder({ orderId: order._id }))
		})
	}

	openModalCancel() {
		this.modalService.open(this.modalCancelId)
	}

	private get cancelSuccessAction() {
		return this.actions$.pipe(ofType(OrderActions.cancelOrderSuccess)).subscribe(() => {
			this.router.navigate([`cancel-reservation/${this.orderId}/success`])
			this.modalService.close(this.modalCancelId)
		})
	}

	private get cancelFailureAction() {
		return this.actions$.pipe(ofType(OrderActions.cancelOrderFailure)).subscribe(() => {
			this.router.navigate(['cancel-reservation/failure'])
			this.modalService.close(this.modalCancelId)
		})
	}
}
